@import url("https://fonts.googleapis.com/css2?family=Barriecito&family=Nunito+Sans&display=swap");

/* James */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Barriecito', sans-serif;
}